<template>
    <div>
        <div v-if="authUserPermission['end-user-search-characteristics-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-6">
                                <h3 class="mb-0">{{ tt('search_by_characteristic_and_value') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <div class="row">
                                        <div class="col-8">
                                            <base-input :name="tt('item_name')" rules="required">
                                                <el-select v-model="table.search" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchItemName" :loading="loading" filterable remote reserve-keyword @change="get()">
                                                    <el-option v-for="si in standardItemName" :key="si.inc" :label="si.inc +' - '+ si.item_name" :value="si.inc"></el-option>
                                                </el-select>
                                            </base-input>
                                        </div>
                                        <div class="col-4 mt-1">
                                            <el-dropdown trigger="click" style="background-color:#172b4d">
                                                <span class="el-dropdown-link btn btn-sm text-white">
                                                    {{ itemTypeFilter }}<i class="el-icon-arrow-down el-icon--right"></i>
                                                </span>
                                                <el-dropdown-menu slot="dropdown">
                                                    <div class="container">
                                                        <p class="filter mb--1"  @click="changeItemType('GEN')">GEN</p>
                                                        <p class="filter mb--1" @click="changeItemType('OEM')">OEM</p>
                                                        <p class="filter mb--1"  @click="changeItemType('SERV')">SERV</p>
                                                    </div>
                                                </el-dropdown-menu>
                                            </el-dropdown> 
                                        </div>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="showTable">
                        <table class="table align-items-center table-flush table-bordered" v-if="!onLoad">
                          <thead class="card-header bg-primary">
                            <tr>
                              <th class="text-white">{{tt('characteristic')}}</th>
                              <th class="text-white">{{tt('value')}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(dinc, key) in searchCharValue.cin_code" v-bind:key="dinc">
                              <td>
                                {{searchCharValue.characteristic[key]}}
                              </td>
                              <td>
                                <el-autocomplete 
                                  v-model="searchCharValue.value.char_value[key]"
                                  :fetch-suggestions="getValue"
                                  :trigger-on-focus="false"
                                  @focus="selectedCinCode(searchCharValue.cin_code[key])"
                                  size="small"
                                  >
                                </el-autocomplete>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <page-loading v-else/>
                    </div>  
                    <div class="text-center my-5" v-else>
                        <img src="/assets/smartcat/data_is_empty.png" alt="Data Is Empty" width="400px">
                        <p class="text-muted">{{ tt('please_search_to_show_data') }}</p>
                    </div>           
                    <div class="card-footer text-right">
                        <base-button size="sm" type="default" :disabled="btnSearchDisable" @click.native="searchMaterial">
                            <span v-if="btnSearchOnLoad"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>{{ tt('search') }}</span>
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';

    export default {  
        watch: {
            'itemTypeFilter' : function(){
                this.get()
            }
        },          
        data() {
            return {       
                showTable: false, 
                onLoad: false, 
                btnSearchDisable: true,
                btnSearchOnLoad: false,  
                table: {
                    search: '',                    
                    data: [],
                    page: [],
                },
                standardItemName: {},
                optionItemName:[],
                loading:false,
                selectSearch: {
                    loadTimeout: null
                },
                searchCharValue: {
                  cin_code: [],
                  characteristic: [],
                  value: {
                      char_value: [],
                      type: 'search-material-by-characteristic',
                      item_name: ''
                  }
                },
                itemTypeFilter:'GEN',
                
            }
        },
        mounted() {
            // this.getStandardItemName();
            // this.list_select_standard_item_name = this.standardItemName;
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        methods: {
            searchMaterial() {
                if(this.$route.params.type == 'workbench'){
                    this.$router.push({ name: 'WorkBench', query: this.searchCharValue.value });
                } else if(this.$route.params.type == 'end-user') {
                    this.$router.push({ name: 'Search Characteristic Search', query: this.searchCharValue.value});
                }
            },

            // SEARCH ITEM NAME
            querySearchItemName(query) {
                if (query.length > 3) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, standardItemName.get(null, {per_page: 'none',item_name: query})).onSuccess(function(response) {
                            context.standardItemName = response.data.data.data.data;   
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.standardItemName = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },

            // GET CHARACTERISTIC
            get() {
                let context = this;   

                this.onLoad = true;
                this.btnSearchDisable = false

                if (context.table.search == '') {
                    context.table.data = [];
                    context.showTable = false;
                } else {
                    Api(context, characteristicItemName.get(null, {search: this.table.search, item_type_filter: this.itemTypeFilter})).onSuccess(function(response) {    
                        context.table.data = response.data.data.data
                        for (var i = 0; i < response.data.data.data.length; i++) {
                            context.searchCharValue.value.item_name =  context.table.search;
                            context.searchCharValue.cin_code[i] = (response.data.data.data[i].cin_code == null) ? '' : response.data.data.data[i].cin_code
                            context.searchCharValue.characteristic[i] = (response.data.data.data[i].characteristic == null) ? '' : response.data.data.data[i].characteristic
                            context.searchCharValue.value.char_value[i] = (response.data.data.data[i].characteristic_value == null) ? '' : response.data.data.data[i].characteristic_value
                        }
                        context.showTable = true;
                        context.onLoad = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.table.data = [];
                            context.onLoad = false;
                        }
                    })
                    .call()
                }            
            },
            changeItemType(type){
                this.itemTypeFilter = type;
            },
            // SEARCH CHARACTERISTIC VALUE
            selectedCinCode(cin_code) {
              let context = this;

              context.charValue = [];

              Api(context, characteristicItemName.get_characteristic_value(null, cin_code, {per_page: 'none'}))
              .onSuccess(function(response) {
                response.data.data.data.data.forEach(function(item, index) {
                    context.charValue[index] = {
                        'code': item['cin_code'],
                        'value': item['characteristic_value']
                    };
                });
              })
              .onError(function(error) {
                if (error.response.status == 404) {
                  context.charValue = [];
                }
              })
              .call();
            },
            getValue(queryString, cb) {
              var links = this.charValue;
              var results = queryString ? links.filter(this.createFilter(queryString)) : links;
              cb(results)
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
        },   
    };
</script>
